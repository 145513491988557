
import { Dialog, Transition } from '@headlessui/react'
import HubspotForm from 'react-hubspot-form'
import { Fragment, useState } from 'react';

interface RequestDemoProps {
  freelancer?: boolean
}

export const RequestDemo = ({freelancer=false}:RequestDemoProps) => {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        className={`font-bold h-14 text-lg
                rounded-2xl  ${freelancer ? 'bg-sroob-blue': 'bg-sroob-dark-blue'} text-white px-4`}
        id={'request-demo'}
        onClick={openModal}
      >
        {freelancer ? 'سجل كمسوّق مهتم' : 'طلب عرض تجريبي'}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium text-center  text-gray-900'
                  >
                    طلب عرض تجريبي
                  </Dialog.Title>
                  <div
                    className={
                      'flex justify-center items-center min-h-[12.5rem]'
                    }
                  >
                    {/* 88090659-23f0-4602-aa7a-2f529ea492cc <- new form */}
                    <HubspotForm
                      portalId='23263921'
                      formId={freelancer ? '487d75ff-c8d4-4444-8e90-c41efe72df9b' : '90d15b4b-5d9c-4686-9219-c246b3ca7059'}
                      region='na1'
                      onSubmit={closeModal}
                      onReady={(form: any) => {
                        console.log('Form ready!')
                      }}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
